<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {};
  },
};
</script>

<style lang="scss">
@import "./assets/reset.css";
@import "./assets/base.scss";
@import "./assets/_breakpoints.scss";
// @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');
// #app {
//   font-family: "Open Sans", sans-serif;
//   font-weight: 400;
//   font-size: 18px;
// }
</style>
