import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('../views/PageNotFounded.vue')
  },
  {
    path: '/result/:orderUID',
    name: 'result',
    component: () => import('../views/ResultPage.vue')
  },
  {
    path: '/:params',
    component: () => import('../views/DynamicPage.vue')
  },
  {
    path: '/universal/payment-declined',
    name: 'payment-declined',
    component: () => import('../views/universal/UniversalUnsuccessfull.vue')
  },
  {
    path: '/universal/time-is-over',
    name: 'time-is-over',
    component: () => import('../views/universal/UniversalTimeIsOver.vue')
  },
  {
    path: '/universal/already-paid',
    name: 'already-paid',
    component: () => import('../views/universal/UniversalAlreadyPaid.vue')
  },
  {
    path: '/iac/successfull',
    name: 'iac-successfull',
    component: () => import('../views/iac/IacSuccessfull.vue')
  },
  {
    path: '/iac/unsuccessfull',
    name: 'iac-unsuccessfull',
    component: () => import('../views/iac/IacUnsuccessfull.vue')
  },


]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes
})

export default router
